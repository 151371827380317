<template>
  <div>
    <VRow>
      <VCol>
        <VDataIterator
          :items="filteredList"
          hide-default-footer
        >
          <template #default="props">
            <VRow>
              <VCol
                v-for="(item,index) in props.items"
                :key="`goal-card-${index}`"
                cols="12"
              >
                <GoalCardMobile
                  :goal="item"
                  @click="handleClickRow(item.id)"
                />
              </VCol>
            </VRow>
          </template>
          <template #no-data>
            <EmptySection
              data-test="no-data"
              :title="$t('goals.no_goals')"
            />
          </template>
          <template #no-results>
            <EmptySection
              data-test="no-results"
              :title="$t('common.no_results')"
            />
          </template>
        </VDataIterator>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_GOALS_ACTION } from '@/plugins/vuex/actionTypes';
import {
  chipStatusTypes, GOAL_TITLE_MAX_LENGTH, GOAL_TYPES,
} from '@/helpers/constants';
import GoalCardMobile from '@/components/cards/GoalCardMobile.vue';
import EmptySection from '@/components/shared/EmptySection.vue';

export default {
  name: 'GoalsListMobile',
  components: {
    EmptySection,
    GoalCardMobile,
  },
  chipStatusTypes,
  inject: ['Names'],
  props: {
    type: {
      type: String,
      default: GOAL_TYPES.personal,
      validator: (value) => [GOAL_TYPES.personal, GOAL_TYPES.trainee].includes(value),
    },
    traineeGoals: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      default: 0,
    },
    filter: {
      type: [Array],
      default: () => [],
    },
  },
  GOAL_TITLE_MAX_LENGTH,
  data() {
    return {
      selectedFilters: [],
      headers: [
        {
          text: this.$t('goals.header_name'),
          value: 'name',
          sortable: false,
          width: '33%',
        },
        {
          text: this.$t('goals.header_description'),
          value: 'description',
          sortable: false,
          width: '33%',
        },
        {
          text: this.$t('goals.header_state'),
          value: 'state',
          sortable: false,
          width: '17%',
        },
        {
          text: this.$t('goals.header_deadline'),
          value: 'deadline',
          sortable: false,
          width: '17%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('goal', ['goals']),
    listGoals() {
      if (this.type === GOAL_TYPES.trainee) {
        return this.traineeGoals;
      }
      return this.goals;
    },

    filteredList() {
      return this.listGoals
        .filter((v) => (!this.filter.length || this.filter.some(() => (this.filter.includes(v.state)))));
    },
  },
  async mounted() {
    if (this.type === GOAL_TYPES.personal) {
      try {
        await this.loadGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      }
    }
  },
  methods: {
    ...mapActions('goal', { loadGoals: GET_GOALS_ACTION }),
    deadlineDate(goal) {
      return this.$dayjs(goal.deadline)
        .format('D MMMM YYYY');
    },
    getRouteTo(goalId) {
      if (this.type === GOAL_TYPES.trainee) {
        const { userId } = this;
        return {
          name: this.Names.R_APP_TRAINEE_GOAL_VIEW,
          params: {
            type: GOAL_TYPES.trainee,
            userId,
            goalId,
          },
        };
      }
      return {
        name: this.Names.R_APP_GOAL_VIEW,
        params: { goalId },
      };
    },
    handleClickRow(item) {
      const route = this.getRouteTo(item.id);
      this.$router.push(route);
    },
  },
};
</script>
<style scoped>
</style>
