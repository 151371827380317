<template>
  <VContainer
    v-if="!isMobile"
    class="pa-0"
  >
    <VRow>
      <VCol>
        <VRow>
          <VCol cols="12">
            <h1
              data-test="goals-view-h1"
              class="tt-text-headline-1"
            >
              {{ $t('menu.goals') }}
            </h1>
          </VCol>
          <VCol cols="12">
            <GoalsList />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <DialogWrapper
      v-model="isShownGoalForm"
      fullscreen
      @click:outside="hideGoalForm"
    >
      <GoalForm
        :edit="false"
        @close="hideGoalForm"
        @save="saveGoalForm"
      />
    </DialogWrapper>
  </VContainer>
  <div v-else>
    <AppBarMobile height="auto">
      <VRow
        align="center"
        dense
      >
        <VCol
          offset="2"
          cols="8"
          class="text-center"
        >
          <h1
            data-test="goals-view-h1"
            class="tt-text-title-1 tt-light-mono-4--text"
          >
            {{ $t('menu.goals') }}
          </h1>
        </VCol>
        <VCol cols="2">
          <TTBtn
            icon
            data-test-label="show-goal-form"
            @click="showGoalForm"
          >
            <VIcon color="tt-light-mono-4">
              fas fa-plus
            </VIcon>
          </TTBtn>
        </VCol>
      </VRow>
      <template #extension>
        <TabsWrapper
          v-model="selectedTab"
          height="26"
          :tabs="filterChips"
          dark
          centered
          color="white"
          underline="none"
          data-test="tabs"
        />
      </template>
    </AppBarMobile>
    <VContainer>
      <VRow>
        <VCol cols="12">
          <GoalsListMobile :filter="filter" />
        </VCol>
      </VRow>
      <DialogWrapper
        v-model="isShownGoalForm"
        fullscreen
        @click:outside="hideGoalForm"
      >
        <GoalForm
          :edit="false"
          @close="hideGoalForm"
          @save="saveGoalForm"
        />
      </DialogWrapper>
    </VContainer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_GOALS_ACTION } from '@/plugins/vuex/actionTypes';
import GoalsList from '@/components/goals/GoalsList.vue';
import GoalForm from '@/components/goals/GoalForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import AppBarMobile from '@/components/app/AppBarMobile.vue';
import TabsWrapper from '@/components/shared/TabsWrapper.vue';
import { PURPOSE_STATES } from '@/helpers/constants';
import GoalsListMobile from '@/components/goals/GoalsListMobile.vue';

export default {
  name: 'GoalsView',
  components: {
    GoalsListMobile,
    TabsWrapper,
    AppBarMobile,
    GoalsList,
    GoalForm,
    DialogWrapper,
  },
  data: () => ({
    isShownGoalForm: false,
    selectedTab: '',
    tabs: [
      {
        text: 'В работе',
        value: 'inwork',
      },
      {
        text: 'Завершенные',
        value: 'completed',
      },
      {
        text: 'Архив',
        value: 'archive',
      },
    ],
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    filter() {
      return this.filterChips.find((element) => element.value === this.selectedTab)?.states ?? [];
    },
    filterChips() {
      return [
        {
          text: this.$t('goals.filter_in_work'),
          value: 'inwork',
          states: [PURPOSE_STATES.NEW],
        },
        {
          text: this.$t('goals.filter_completed'),
          value: 'completed',
          states: [PURPOSE_STATES.COMPLETED, PURPOSE_STATES.FAILED],
        },
        {
          text: this.$t('goals.filter_archive'),
          value: 'archive',
          states: [PURPOSE_STATES.REJECTED],
        },
      ];
    },
  },
  mounted() {
    this.$root.$on('show-goal-form', this.showGoalForm);
  },
  methods: {
    ...mapActions('goal', { loadGoals: GET_GOALS_ACTION }),
    showGoalForm() {
      this.isShownGoalForm = true;
    },
    hideGoalForm() {
      this.isShownGoalForm = false;
    },
    async saveGoalForm() {
      try {
        await this.loadGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.hideGoalForm();
      }
    },
  },
};
</script>
