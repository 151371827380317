var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VRow',[_c('VCol',[_c('VChipGroup',{attrs:{"data-test":"tt-chip-group","multiple":""},model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}},_vm._l((_vm.filterChips),function(filterChip,i){return _c('VChip',{key:("filter-chip-" + i),attrs:{"ripple":false,"outlined":true}},[_vm._v(" "+_vm._s(filterChip.name)+" ")])}),1)],1)],1),_c('VRow',[_c('VCol',[_c('OTable',{ref:"dragable",attrs:{"hide-default-footer":"","items":_vm.filteredList,"headers":_vm.headers,"items-per-page":-1,"item-class":_vm.generateItemClass},on:{"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"goals-list-item-name"}},[_vm._v(" "+_vm._s(_vm.cutString(item.name, _vm.$options.GOAL_TITLE_MAX_LENGTH))+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"goals-list-item-description"}},[_vm._v(" "+_vm._s(_vm.cutString(item.description ? item.description : '-', _vm.$options.GOAL_TITLE_MAX_LENGTH))+" ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('ChipStatus',{attrs:{"data-test":"goals-list-item-chipstatus","status":item.state,"date":item.deadline,"item-type":_vm.$options.chipStatusTypes.PURPOSE,"show-icon":""}})]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"goals-list-item-deadline"}},[_vm._v(" "+_vm._s(_vm.deadlineDate(item))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }