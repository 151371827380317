<template>
  <div>
    <VRow>
      <VCol>
        <!-- TODO проблема с TTChip, почему-то считает неправильный индекс при выборке -->
        <!-- Заменил с TTChopGroup на VChipGroup потому что крашит ошибки в консоль с observe -->
        <VChipGroup
          v-model="selectedFilters"
          data-test="tt-chip-group"
          multiple
        >
          <VChip
            v-for="(filterChip, i) in filterChips"
            :key="`filter-chip-${i}`"
            :ripple="false"
            :outlined="true"
          >
            {{ filterChip.name }}
          </VChip>
        </VChipGroup>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <OTable
          ref="dragable"
          hide-default-footer
          :items="filteredList"
          :headers="headers"
          :items-per-page="-1"
          :item-class="generateItemClass"
          @click:row="handleClickRow"
        >
          <template #item.name="{item}">
            <div data-test="goals-list-item-name">
              {{ cutString(item.name, $options.GOAL_TITLE_MAX_LENGTH) }}
            </div>
          </template>
          <template #item.description="{item}">
            <div data-test="goals-list-item-description">
              {{ cutString(item.description ? item.description : '-', $options.GOAL_TITLE_MAX_LENGTH) }}
            </div>
          </template>
          <template #item.state="{item}">
            <ChipStatus
              data-test="goals-list-item-chipstatus"
              :status="item.state"
              :date="item.deadline"
              :item-type="$options.chipStatusTypes.PURPOSE"
              show-icon
            />
          </template>
          <template #item.deadline="{item}">
            <div data-test="goals-list-item-deadline">
              {{ deadlineDate(item) }}
            </div>
          </template>
        </OTable>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import ChipStatus from '@/components/chips/ChipStatus.vue';
import { mapActions, mapGetters } from 'vuex';
import { GET_GOALS_ACTION } from '@/plugins/vuex/actionTypes';
import {
  chipStatusTypes, GOAL_TITLE_MAX_LENGTH, GOAL_TYPES, PURPOSE_STATES,
} from '@/helpers/constants';
import { cutString } from '@/helpers/stringHelpers';
import OTable from '@/components/shared/OTable.vue';

export default {
  name: 'GoalsList',
  components: {
    OTable,
    ChipStatus,
  },
  chipStatusTypes,
  inject: ['Names'],
  props: {
    type: {
      type: String,
      default: GOAL_TYPES.personal,
      validator: (value) => [GOAL_TYPES.personal, GOAL_TYPES.trainee].includes(value),
    },
    traineeGoals: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      default: 0,
    },
  },
  GOAL_TITLE_MAX_LENGTH,
  data() {
    return {
      selectedFilters: [],
      headers: [
        {
          text: this.$t('goals.header_name'),
          value: 'name',
          sortable: false,
          width: '33%',
        },
        {
          text: this.$t('goals.header_description'),
          value: 'description',
          sortable: false,
          width: '33%',
        },
        {
          text: this.$t('goals.header_state'),
          value: 'state',
          sortable: false,
          width: '17%',
        },
        {
          text: this.$t('goals.header_deadline'),
          value: 'deadline',
          sortable: false,
          width: '17%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('goal', ['goals']),
    listGoals() {
      if (this.type === GOAL_TYPES.trainee) {
        return this.traineeGoals;
      }
      return this.goals;
    },
    filterChips() {
      return [
        {
          name: this.$t('goals.filter_in_work'),
          states: [PURPOSE_STATES.NEW],
        },
        {
          name: this.$t('goals.filter_completed'),
          states: [PURPOSE_STATES.COMPLETED, PURPOSE_STATES.FAILED],
        },
        {
          name: this.$t('goals.filter_archive'),
          states: [PURPOSE_STATES.REJECTED],
        },
      ];
    },
    filteredList() {
      return this.listGoals.filter((v) => (
        !this.selectedFilters.length || this.selectedFilters.some((i) => (
          this.filterChips[i].states.includes(v.state)
        ))
      ));
    },
  },
  async mounted() {
    if (this.type === GOAL_TYPES.personal) {
      try {
        await this.loadGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      }
    }
  },
  methods: {
    ...mapActions('goal', { loadGoals: GET_GOALS_ACTION }),
    cutString,
    deadlineDate(goal) {
      return this.$dayjs(goal.deadline)
        .format('D MMMM YYYY');
    },
    getRouteTo(goalId) {
      if (this.type === GOAL_TYPES.trainee) {
        const { userId } = this;
        return {
          name: this.Names.R_APP_TRAINEE_GOAL_VIEW,
          params: {
            type: GOAL_TYPES.trainee,
            userId,
            goalId,
          },
        };
      }
      return {
        name: this.Names.R_APP_GOAL_VIEW,
        params: { goalId },
      };
    },
    handleClickRow(item) {
      const route = this.getRouteTo(item.id);
      this.$router.push(route);
    },
    generateItemClass() {
      return 'cursor--pointer';
    },
  },
};
</script>
<style scoped>
.goal-item {
  cursor: pointer;
}
</style>
